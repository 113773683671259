

export default {
  name: 'LanguageSelect',
  data: () => ({}),
  computed: {
    locales() {
      return this.$i18n.locales
    },
  },
  watch : {
    '$i18n.locale': function () {
      this.$store.dispatch('getGroups')
      this.$store.dispatch('getFlows')
    }
  },
  methods: {
    setLocale(locale) {
      this.$i18n.setLocale(locale)
      if (this.$auth.loggedIn) {
        this.$store.dispatch('getTemplates')
      }
    },
  },
}
